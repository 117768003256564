<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <ProjectUserStoryForm
      :disabled="loading[`put:api/project-user-stories/${selectedProjectUserStory.id}`]"
      :errors="projectUserStoryValidationErrors"
      :project-user-story="selectedProjectUserStory"
      @clear:errors="CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
      @delete="onDelete"
      @delete:attachment="
        deleteProjectUserStoryAttachment({
          projectUserStory: selectedProjectUserStory,
          attachment: $event,
        })
      "
      @update:comment-count="
        UPDATE_PROJECT_USER_STORY({
          ...selectedProjectUserStory,
          comments_count: $event,
        })
      "
      @upload:attachments="pendingAttachments = $event"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import store from '@/store';
import ProjectUserStoryForm from '@/components/forms/ProjectUserStoryForm';

export default {
  name: 'EditProjectUserStory',

  components: { ProjectUserStoryForm },

  data() {
    return {
      pendingAttachments: [],
    };
  },

  computed: {
    ...mapState('projectUserStories', [
      'selectedProjectUserStory',
      'projectUserStoryValidationErrors',
    ]),
    ...mapGetters(['loading']),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('projectUserStories/editProjectUserStory', +to.params.projectUserStoryId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.getBackRoute(to));
      });
  },

  methods: {
    ...mapActions('projectUserStories', [
      'updateProjectUserStory',
      'deleteProjectUserStory',
      'saveUserStoriesOrder',
      'deleteProjectUserStoryAttachment',
      'uploadProjectUserStoryAttachments',
    ]),
    ...mapMutations('projectUserStories', [
      'CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS',
      'UPDATE_PROJECT_USER_STORY',
    ]),

    onSave(projectUserStory) {
      const oldSprintId = this.selectedProjectUserStory.sprint_id;
      this.updateProjectUserStory(projectUserStory).then((updatedUserStory) => {
        if (this.pendingAttachments.length) {
          this.uploadProjectUserStoryAttachments({
            projectUserStory: updatedUserStory,
            attachments: this.pendingAttachments,
          });
        }
        if (updatedUserStory.sprint_id !== oldSprintId) {
          this.saveUserStoriesOrder(updatedUserStory.sprint_id);
        }
        this.goBack();
      });
    },

    async onDelete() {
      const wasDeleted = await this.deleteProjectUserStory(this.selectedProjectUserStory);
      if (wasDeleted) {
        this.goBack();
      }
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>
